import * as React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { StaticImage } from "gatsby-plugin-image"

const IndexPage = () => (
  <Layout>
    <Seo title="Home" />
    <h1>Run with others remotely.</h1>
    <p>
      Runder is a new running app that allows you to run with family and friends who are far away from you.
      The distance you and your friend are running is displayed in real time on the screen of your smartphone. Of course, you can also run alone.
    </p>
    <p>
      With your smartphone in hand, let's run!
    </p>
    <p>
      <a href='https://play.google.com/store/apps/details?id=com.shundroid.runder'>
        <StaticImage
          src="../images/google-play.png"
          alt="Get it on Google Play"
          placeholder="blurred"
          height={45}
          quality="100"
          style={{margin: "1%"}}
        />
      </a>
      <a href='https://apps.apple.com/app/runder-co-running/id1605128546'>
        <StaticImage
          src="../images/app-store.png"
          alt="Download on the App Store"
          placeholder="blurred"
          height={45}
          quality="100"
          style={{margin: "1%"}}
        />
      </a>
    </p>
    <p>
      <Link to="/privacy-policy/">Privacy Policy</Link> <br />
      <Link to="/terms-of-service/">Terms of Service</Link> <br />
    </p>
  </Layout>
)

export default IndexPage
